import React from "react";
import flowerLeft from "./assets/flowerLeft.svg";
import flowerRight from "./assets/flowerRight.svg";
import flowerMiddle from "./assets/flowerMiddle.svg";
import invitation from "./assets/invitation.jpeg";
import "./App.css";
import "./fonts/HanyiSentyPomelo.ttf";
import { PartyTimeline } from "./components/PartyTimeline";

function AbsoluteBackgroundFlowers() {
  return (
    <>
      <img
        src={flowerLeft}
        alt="flower left"
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <img
        src={flowerRight}
        alt="flower right"
        style={{ position: "absolute", top: 45, right: 0 }}
      />
      <img
        src={flowerMiddle}
        alt="flower middle"
        style={{ position: "absolute", top: 324, right: 0 }}
      />
    </>
  );
}

function InvitationSection() {
  return (
    <div className="English-section fst-italic">
      <div className="font-dancing text-h1 lh-1 margin-ver-small our-name">
        Celia
      </div>
      <div className="font-dancing text-h4 lh-1.2 our-name">&</div>
      <div className="font-dancing text-h1 lh-1 our-name">Andus</div>
      <div className="font-dancing text-getting-married">Getting Married!</div>
      <div className="font-crimson text-h4 margin-ver-small">
        Request the honour of your presence
      </div>
      <div className="font-crimson text-h4 margin-ver-small">
        at our wedding celebration
      </div>
      <div className="font-dancing text-date">14 . 03 . 2023</div>
      <div className="font-crimson text-h3 lh-sm">Yat Heen, 2F</div>
      <div className="font-crimson text-h3 lh-sm">Alva Hotel by Royal</div>
      <div className="font-crimson text-h3 lh-sm">
        1 Yuen Hong Street, Shatin
      </div>
      <div
        className="font-crimson text-h4 lh-1"
        style={{ marginTop: 35, marginBottom: 10 }}
      >
        Reception 6:00pm
      </div>
      <div className="font-crimson text-h4 lh-1">Banquet 8:00pm</div>
      <img src={invitation} alt="invitation" className="invitation" />
    </div>
  );
}

function GoogleMap() {
  return (
    <div className="Secondary-container">
      <iframe
        title="hotel-location"
        width="100%"
        height="250"
        loading="lazy"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAywAiEobpG0iJryB0cZlt2HfbcZHwus2Q
      &q=ALVA HOTEL BY ROYAL"
      />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <div className="Main-container container-md ">
        <AbsoluteBackgroundFlowers />
        <InvitationSection />
        <GoogleMap />
        <PartyTimeline />
        <div
          className="font-dancing"
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#6D0000",
            color: "#ffffff",
            padding: "4px 0",
          }}
        >
          Credit to Celia & Andus ฅ^•ﻌ•^ฅ @ 2023
        </div>
      </div>
    </div>
  );
}

export default App;

import React from "react";
import "./PartyTimeline.css";
import timelineIcon1 from "../assets/timelineIcon1.svg";
import timelineIcon2 from "../assets/timelineIcon2.svg";
import timelineIcon3 from "../assets/timelineIcon3.svg";
import timelineIcon4 from "../assets/timelineIcon4.svg";
import timelineIcon5 from "../assets/timelineIcon5.svg";
import timelineIcon6 from "../assets/timelineIcon6.svg";
import heartLine from "../assets/heartLine.svg";

export function PartyTimeline() {
  return (
    <div className="Third-container">
      <div className="font-amatic timeline-header title-color">
        Party Timeline
      </div>
      <div className="timeline">
        <div className="indicator-wrapper i1">
          <div className="indicator" />
          <img src={timelineIcon1} alt="ti1" className="icon i-place-bottom" />
          <div className="font-amatic text-time t-place-top">18:00</div>
        </div>
        <div className="indicator-wrapper i2">
          <div className="indicator" />
          <img src={timelineIcon2} alt="ti2" className="icon i-place-top" />
          <div className="font-amatic text-time t-place-bottom">20:00</div>
        </div>
        <div className="indicator-wrapper i3">
          <div className="indicator" />
          <img src={timelineIcon3} alt="ti3" className="icon i-place-bottom" />
          <div className="font-amatic text-time t-place-top">20:15</div>
        </div>
        <div className="indicator-wrapper i4">
          <div className="indicator" />
          <img src={timelineIcon4} alt="ti4" className="icon i-place-top" />
          <div className="font-amatic text-time t-place-bottom">21:00</div>
        </div>
        <div className="indicator-wrapper i5">
          <div className="indicator" />
          <img src={timelineIcon5} alt="ti5" className="icon i-place-bottom" />
          <div className="font-amatic text-time t-place-top">21:30</div>
        </div>
        <div className="indicator-wrapper i6">
          <div className="indicator" />
          <img src={timelineIcon6} alt="ti6" className="icon i-place-top" />
          <div className="font-amatic text-time t-place-bottom">22:30</div>
        </div>
      </div>
      <div className="photo-order-section">
        <img src={timelineIcon5} alt="camera" className="photo-order-icon" />
        <img src={heartLine} alt="line" className="heart-line" />
        <div className="name-section font-hanyi">
          <div>新郎新娘</div>
          <div>新郎父母</div>
          <div>新娘父母及妹妹</div>
          <div>新郎新娘，雙方父母及新娘妹妹</div>
          <div>新郎二姑媽一家</div>
          <div>新郎三姑媽一家</div>
          <div>新郎婆婆，姨姨</div>
          <div>新郎大舅父一家</div>
          <div>新郎二舅父</div>
          <div>新郎姨媽一家</div>
          <div>新郎三舅父一家，三舅父外母及舅仔</div>
          <div>新郎父母，姨媽，姨姨及秀儀姨姨</div>
          <div>新郎親戚大合照</div>
          <div>新娘爺爺嫲嫲</div>
          <div>新娘大姑媽一家</div>
          <div>新娘舅父一家</div>
          <div>新娘大姨一家</div>
          <div>新娘存姨一家</div>
          <div>新娘舊姨，表姨，珊姐姐</div>
          <div>新娘細姨一家</div>
          <div>新娘親戚大合照</div>
          <div>新郎大學學會同學</div>
          <div>新郎大學EE同學</div>
          <div>新郎中學同學</div>
          <div>新娘大學學會同學</div>
          <div>新娘ACCC的好朋友Cathy及Carman</div>
          <div>新娘的好朋友Vestina及Eric</div>
          <div>新娘童言密語的好朋友們</div>
          <div>新娘童言密語的好朋友們 ( 及男朋友們 )</div>
          <div>化妝師</div>
          <div>攝影師</div>
        </div>
        <img src={heartLine} alt="line" className="heart-line" />
      </div>
    </div>
  );
}
